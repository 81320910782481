import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import ModalImage from "react-modal-image";

const useStyles = makeStyles(theme => ({
   pagetitle:{
    color: '#231f20',
    fontFamily:'Poppins',
    paddingTop:'30px'
  },
  pagesubtitle:{
   paddingBottom: '40px', 
   fontFamily: 'Poppins'
  },
  img: {
    maxHeight: '70px'
  },
  card: {
    maxHeight: '340px'
  }
}));


export default function Architectural() {
  const classes = useStyles();

  return (
    <div>    
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center"className={classes.pagetitle} gutterBottom>
          ARCHITECTURAL
        </Typography>
        <Typography variant="h5" align="center" className={classes.pagesubtitle}>
      
        </Typography>
      </Container>

      <Container maxWidth="md" component="main">
      <Grid container spacing={5} justify="center" alignItems="center">                    
         
        </Grid>
        </Container> 
        <div style={{marginBottom: '50px'}}></div>
      </div>
    
  );
}