import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import Header1 from '../assets/Header1.jpg'
import Header2 from '../assets/Header2.jpg'
import Header3 from '../assets/Header.jpg'   
import Drawer from './Drawer';
import Contact from './Contact';
import About from './About'
import { Box } from '@material-ui/core';
import BackToTop from './Backtotop';


function Home () {
        return (
          <div>
            <BackToTop/>
            <Box	display={{ xs: 'block', sm: 'none' }}><Drawer/></Box>                  
            <Carousel autoPlay 
            transitionTime={1000} 
            interval={8000} infiniteLoop 
            showThumbs={false}  showStatus={false}>
                <div><img src={Header3} alt="" /></div>
                <div><img src={Header2} alt=""/></div>
                <div><img src={Header1} alt=""/></div> 
            </Carousel>           
            <Contact/>
            <About/>
        </div>
        );
      }
  
 export default Home;
