
import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {  Link } from "react-router-dom";
import Box from '@material-ui/core/Box';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import InputAdornment from '@material-ui/core/InputAdornment';
import { InputBase } from '@material-ui/core';


function Copyright() {
  return (
    <Typography variant="body2" style={{color: '#aaadaa'}}>
      
       <Link to='/privacypolicy' style={{textDecoration: 'none', color: '#aaadaa'}}>Privacy Policy |</Link>
       <Link to='/termsofuse' style={{textDecoration: 'none', color: '#aaadaa'}}> Terms of Use | </Link>
       <Box display={{ xs: 'block', sm: 'none' }}><br/></Box>
      {'Copyright ©     '}
      <Link to='/' color="inherit" 
      style={{textDecoration: 'none', color: '#aaadaa'}}>
         Sea-Land Distributors, LLC.
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
function handleClick(e) {
  e.preventDefault();
  alert('Thankyou! Your email has been recieved');
}
const useStyles = makeStyles(theme => ({
    '@global': {
      ul: {
        margin: 0,
        padding: 0,
        listStyle: 'none',
      },
    },
    footer: {
      borderTop: `1px solid ${theme.palette.divider}`,     
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(3),
      [theme.breakpoints.up('sm')]: {
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    headerlink:{
      textDecoration: 'none',
      color: 'white',
      fontFamily: 'Poppins',
      fontSize: '25px',
  },
    link:
    {
      textDecoration: 'none',
      color: '#e4e4e4',
      fontSize: '16px',
      fontFamily: 'Poppins'
    },
    textfield:{
      margin: '5px',
      backgroundColor: 'white',
      color: '#213C54',
      borderRadius: '20px',
      paddingLeft: '8px'
  },
    signup:{
      color: 'white', 
      border: '2px solid white', 
      padding: '4px',
      margin: '3px',
    }, 
  }));

export default function Footer() {
  const classes = useStyles(); 
  return (
    <div style={{backgroundColor: '#003469'}}>
    <Container maxWidth="lg" component="footer" className={classes.footer} >
        <Grid container spacing={2}>        
            <Grid item xs={6} sm={3} md={4} lg={4} >
              <Typography gutterBottom className={classes.headerlink}>
               HAAS Stainless              
              </Typography>
              <ul>    
              <Link to='/' className={classes.link}><li>Home  </li></Link>   
              <a href='#haasprostaffinstallers' className={classes.link}><li>HAAS Pro Staff Installers</li>  </a>    
              <a href='#wheretobuy' className={classes.link}><li>Where to Buy  </li>  </a>               
              <a href='#media' className={classes.link}><li>Media  </li>  </a>               
              <a href='#about' className={classes.link}><li>About  </li>  </a>  
              <a href='#stainlesssteelfacts' className={classes.link}><li>Stainless Steel Facts  </li>  </a>                         
              </ul>
            </Grid>  
            <Grid item xs={6} sm={3} md={4} lg={4}>
              <Typography gutterBottom className={classes.headerlink}>                
               Quick Links
              </Typography>                 
              <ul>    
              <Link to='/architectural' className={classes.link}><li>Architectural    </li></Link>  
              <Link to='/marine' className={classes.link}><li>Marine </li></Link>
              <Link to='/rigging' className={classes.link}><li>Rigging  </li></Link>
              <Link to='/hardware' className={classes.link}><li>Hardware </li></Link>
              </ul>              
            </Grid>  
            <Grid item xs={12} sm={6} md={4} lg={4}>
            <Typography className={classes.headerlink} style={{paddingLeft:'5px', paddingBottom: '5px', fontSize: '22px'}}> N E W S L E T T E R</Typography>             
            <Typography className={classes.link} style={{paddingLeft:'5px'}}>Sign up for new HAAS Stainless releases</Typography>                       
            <form >
            <InputBase className={classes.textfield}
              inputProps={{ 'aria-label': 'naked' }}      
              endAdornment={
                <InputAdornment position="end">
                <ArrowRightIcon fontSize='large' onClick={handleClick} style={{cursor:'pointer'}}>
                  </ArrowRightIcon>
                </InputAdornment>
              }
            />
           </form>  
        </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
        <div style={{paddingBottom: '30px'}}></div>
      </Container>
      </div>
  );
}