import './App.css';
import React from "react";
import {  BrowserRouter as Router,  Switch,  Route} from "react-router-dom";
import Home from './components/Home';
import Termsofuse from './components/Termsofuse';
import Privacy from './components/Privacy'
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Contactform from './components/Contactform'
import  Box   from '@material-ui/core/Box';
import Drawer from './components/Drawer'
import Footer from './components/Footer';
import Navbar from './components/Appbar'
import Architectural from './components/pages/Architectural'
import Marine from './components/pages/Marine'
import Rigging from './components/pages/Rigging'
import Hardware from './components/pages/Hardware'

 function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

 function App() {
  return (
    <div>  
       <ScrollToTop />  
       <Navbar/>   
      <Box display={{ md: 'block', lg: 'none' }} ><Drawer/></Box>
      <Box display={{ xs: 'none', sm: 'block', md: 'none' }}><br/><br/><br/></Box>
      <Switch>
      <Route path="/contactform" component={Contactform} ></Route>
      <Route path="/privacypolicy" component={Privacy} ></Route>
      <Route path="/termsofuse" component={Termsofuse}></Route>
      <Route path="/architectural" component={Architectural}></Route>
      <Route path="/marine" component={Marine}></Route>
      <Route path="/rigging" component={Rigging}></Route>
      <Route path="/hardware" component={Hardware}></Route>
        <Route exact path="/" component={Home}></Route>
      </Switch>
      <Footer/>
    </div>
  );
}

export default App;
