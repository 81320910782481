import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'


const useStyles = makeStyles(theme => ({
  root: {    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },    
  },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
    fontFamily: 'Poppins'
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },
  link:
    { 
      fontFamily: 'Poppins',
      fontSize: '20px',
      textDecoration: 'none',
      color: 'white',
      display:'flex',
      justifyContent: 'space-evenly',
      '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .3s ease',
        color:'#e4e4e4',
     },   
    },
    reslink:
    {
        textDecoration: 'none',
        color: '#213C54',
    },
     reslink2:
    {
        textDecoration: 'none',
        color: '#213C54',
       fontSize:'15px'
    },
    
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,  
  window: PropTypes.func,
};

function ElevateAppBar(props) {
  const classes = useStyles();
 

  return (    
    <React.Fragment>
      <Topnav/>
      <ElevationScroll {...props}> 
      <AppBar className={classes.root} position="sticky" style={{ backgroundColor: '#003469'  ,
           color: 'white'}}>        
        <Toolbar >
        <Typography  align="center"> 
        <Scroll/>       
          </Typography>
          <Typography variant = "subheading"color="inherit" style={{flexGrow:1}}> 
          <Link to="/architectural" className={classes.link}>ARCHITECTURAL
          </Link></Typography>
          <Typography variant = "subheading" color="inherit" style={{flexGrow:1}}> 
          <Link to="/marine" className={classes.link}>MARINE</Link></Typography>
          <Typography variant = "subheading" color="inherit" style={{flexGrow:1}}> 
          <Link to="/rigging" className={classes.link}>RIGGING</Link></Typography>
          <Typography variant = "subheading"  color="inherit"style={{flexGrow:1}} >
            <Link to="/hardware" className={classes.link}>HARDWARE
            </Link></Typography>    
      </Toolbar>
      </AppBar>
      </ElevationScroll>    
      
    </React.Fragment>
  );
};


export default ElevateAppBar;