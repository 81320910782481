import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles({
  text:{
    color: '#231f20',
    marginBottom: '65px',
    marginTop: '40px',
    fontFamily:'Poppins',
  },   
  bg:{
    backgroundColor: '#d6d9dd',
    paddingTop: '50px',
    paddingBottom: '100px'    
  },
  aboutimg: {
    height: '380px',
  },
  pagecontent: {
    fontFamily: 'Poppins',
    fontSize: '16px'
  }
});

function  Contact() {
  const classes = useStyles();

    return (      
      <div id="about" className={classes.bg}>
         <Typography component="h3" variant="h2" align="center" className={classes.text} gutterBottom>
          About Us
        </Typography>       
        <Container maxWidth="lg" component="main" >   
          <Grid container spacing={3} alignItems="center" justify="center">  
          <Grid item md={3} xs={12} style={{ flexBasis: 'auto'}}>
           </Grid>  
            <Grid item md={9} xs={12}> 
             <Typography >   
                 <p className={classes.pagecontent}> With over 1,100 products, HAAS Stainless has become one of America’s largest suppliers and manufacturers of stainless steel architectural, rigging and marine hardware, chain and cable. And the innovative HAAS cable rail system is Made in USA at Sea-Land’s manufacturing facility using state of the art swiss style machining equipment. </p>                      
              </Typography>
              </Grid>   
          </Grid>            
        </Container>                     
      </div>
     
    );
  }

 
export default Contact;