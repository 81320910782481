import React from 'react';
import Typography from '@material-ui/core/Typography';
import logo from '../assets/logo.svg'
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles({
reslogo:
{
    height: '70px',
    margin: '10px',   
},
logo:
{
    height: '100px',
    margin: '10px',
    marginTop: '20px',
    marginLeft: '30px',
},
toplink:
{ 
  fontFamily: 'Poppins',
  fontSize: '24px',
  textDecoration: 'none',
  color: '#213C54',  
  margin: 'auto',
  padding:'5px',
'&:hover': {
  background: "lightgrey",
},
},
});

function Test2 () { 
  const classes = useStyles();

  return (
    <React.Fragment>   
     <Box display={{ xs: 'none', sm: 'none', md: 'none', lg: 'block' }}>   
     <Grid  container  direction="row"  justify="space-around"  alignItems="center" style={{backgroundColor: '#e4e4e4'}}>
      <Grid container item lg={5} md={5}>
      <Link to="/">
             <img src={logo} className={classes.logo} alt=""/></Link>
      </Grid>
      <Grid container item lg={7} md={7} justify="flex-end">

          <Typography  className = {classes.toplink} color="inherit" >
           <a href="/#stainlesssteelfacts" className={classes.toplink}>Stainless Steel Facts</a></Typography>

          <Typography  className = {classes.toplink} color="inherit" > 
          <a href="/#about" className={classes.toplink}>About</a></Typography>

          <Typography  className = {classes.toplink} color="inherit" > 
          <a href="/#media" className={classes.toplink}>Media</a></Typography>

          <Typography  className = {classes.toplink} color="inherit" >
           <a href="/#wheretobuy" className={classes.toplink}>Where to Buy</a></Typography>

          <Typography  className = {classes.toplink} color="inherit" >
           <a href="/#haasprostaffinstallers" className={classes.toplink}>HAAS Pro Staff Installers</a></Typography>

          <Typography className = {classes.toplink} color="inherit" > 
          <Link to="/" className={classes.toplink}>Home</Link></Typography>
     </Grid>
     </Grid>      
      </Box>  
      {/* mobile  */}
      <Box	display={{ xs: 'block', sm: 'none' }} > 
      <div align="center" style={{marginTop: '55px'}}>               
      </div>        
      </Box>      
    </React.Fragment>
  );
};

export default Test2;